import React from 'react'

const TakeNotes = () => {
  return (
    <section id='TakeNotes'>
        <h1>Take <span>Notes</span></h1>
        <h3>
          Work In Progress!
        </h3>
    </section>
  )
}

export default TakeNotes
