import React from 'react'

const Calculator = () => {
  return (
    <section id='Calculator'>
        <h1>Calculator</h1>
        <h3>
          Work In Progress!
        </h3>
    </section>
  )
}

export default Calculator
